import {sortBy} from 'lodash-es'
import { BUYER, SUPPLIER, SERVICE_PROVIDER } from '@tradecafe/types/core'

export interface ShippingLogHeader {
    name: string
    id: string
    selected?: boolean
    disabled?: boolean
    type?: string
    template?: string
}

const availableShippingLog: ShippingLogHeader[] = [
    {name: 'Deal #', id: 'deal_no', selected: true, disabled: true},
    {name: 'Status', id: 'status', selected: true},
    {name: 'Date', id: 'date', type: 'date'},
    {name: 'Supplier', id: 'supplier'},
    {name: 'Buyer', id: 'buyer'},

    {name: 'Origin', id: 'origin'},
    {name: 'Destination', id: 'destination'},
    {name: 'Pickup', id: 'pickup'},
    {name: 'Delivery', id: 'delivery', selected: true},
    {name: 'Delivery Period', id: 'delivery_period'},
    {name: 'Pickup Date', id: 'pickup_date', type: 'date', selected: true},
    {name: 'Pickup Time', id: 'pickup_time', type: 'time'},
    {name: 'Delivery Date', id: 'delivery_date', type: 'date', selected: true},
    {name: 'Delivery Time', id: 'delivery_time', type: 'time'},

    {name: 'Import Permit', id: 'import_permit'},
    {name: 'Pedimento #', id: 'import_no'},
    {name: 'Container number', id: 'container_num'},
    {name: 'Courier Track. #', id: 'courier_tracking_no',
        template: `<div class="ui-grid-cell-contents" ng-if="row.entity.courierTrackings" title="{{row.entity.courierTrackingNums}}">
    <span ng-repeat="item in row.entity.courierTrackings"><a ng-href="{{item.tracking_link}}" target="_blank">{{item.tracking_number}}</a> </span>
  </div>`,
    },
    {name: 'Truck', id: 'truck'},
    {name: 'Vessel', id: 'vessel'},
    {name: 'Shipping Line', id: 'shipping_line'},
    {name: 'On Board Date', id: 'on_board_date', type: 'date'}, // TODO: use attributes.actual_pickup_date

    {name: 'Product', id: 'product', selected: true},
    {name: 'Description Of Goods', id: 'desc_of_goods'},
    {name: 'Net Weight', id: 'net_wight', type: 'number'},
    {name: 'Wrapping', id: 'wrapping'},
    {name: 'No of Packages', id: 'no_of_packages'},
    {name: 'Type of Packages', id: 'type_of_packages'},
    {name: 'Weight of one package', id: 'size_of_package'},
    {name: 'Unit Sell Price', id: 'unit_sell_price'},
    {name: 'Selling Ref.#', id: 'selling_ref_no'},

    {name: 'Booking Number', id: 'booking_num'},

    {name: 'Actual Payment Date', id: 'actual_payment_date', type: 'date'},
    {name: 'Additional Info', id: 'additional_info'},

    {name: 'Consignee', id: 'consignee'},
    {name: 'Shipper', id: 'shipper'},

    // {name: 'Doc', id: 'doc'},
    {name: 'Docs Status', id: 'docs_status'},
    {name: 'Value of Docs Release', id: 'value_of_docs_release'},

    {name: 'Inv.#', id: 'inv_no'},
    {name: 'Mex Inv #', id: 'vendor_invoice_number_mx'},
    {name: 'Invoice Date', id: 'inv_date', type: 'date'},
    {name: 'Due Date', id: 'due_date', type: 'date', selected: true},
    {name: 'Invoice Amount', id: 'inv_amount', type: 'currency'},
    {name: 'Invoice Amount Extra Costs', id: 'inv_extra_costs'},

    {name: 'Prepayment Amount', id: 'prepayment_amount', type: 'currency'},
    {name: 'Payment Received', id: 'payment_received', type: 'currency'},
    {name: 'Payment Terms', id: 'payment_terms'},
    {name: 'Prepayment %', id: 'prepayment_percent'},
    {name: 'Outstanding Balance', id: 'outstanding_balance', type: 'currency'},
    {name: 'Supplier Paid Date', id: 'supplier_paid_date', type: 'date'},

    {name: 'Voyage #', id: 'voyage_no'},
    {name: 'EPD', id: 'epd_date', type: 'date'},
    {name: 'ERD', id: 'erd_date', type: 'date'},
    {name: 'Comment', id: 'comment'},
    {name: 'Est #', id: 'establishments'},
    {name: 'Brand', id: 'brand'},
    {name: 'Forwarder', id: 'forwarder'},
    {name: 'Exact Dropoff Address', id: 'exact_dropoff_address', type: 'address'},
    {name: 'Current Carrier Location', id: 'current_carrier_location', selected: true},
    {name: 'Shipment Tracking Status', id: 'shipment_tracking_status'},
    {name: 'Ocean Carrier ETA', id: 'carrier_eta'},
]

const availableSupplierShippingLog = [
    {name: 'Deal #', id: 'deal_id', selected: true, disabled: true},
    {name: 'Status', id: 'status', selected: true},
    {name: 'Cut Off', id: 'cutOff', type: 'date', selected: true},
    {name: 'Est. Loading Date', id: 'estLoadingDate', type: 'date', selected: true},
    {name: 'Loading Date', id: 'loadingDate', type: 'date', selected: true},
    {name: 'Loading Address', id: 'loadingAddress', type: 'address', selected: true},
    {name: 'Vessel', id: 'vessel', selected: true},
    {name: 'Booking #', id: 'booking', selected: true},
    {name: 'Courier Tracking #', id: 'trackingNum', selected: true},
    {name: 'Destination', id: 'destination', selected: true},
    {name: 'Supplier Ref. Number', id: 'supplierRef', selected: true},
    {name: 'Product Name', id: 'productName', selected: true},
    {name: 'Est. Weight', id: 'estWeight', type: 'number', selected: true},
    {name: 'Actual Weight', id: 'weight', type: 'number', selected: true},
]

const availableServiceProviderShippingLog = [
    {name: 'Deal #', id: 'deal_id', selected: true, disabled: true},
    {name: 'Loading Date', id: 'load_date', type: 'date'},
    {name: 'Dropoff Date', id: 'dropoff_date', type: 'date'},
    {name: 'Exact Loading Address', id: 'exact_loading_address', type: 'address'},
    {name: 'Loading Loc.', id: 'loading_loc', type: 'address'},
    {name: 'Exact Dropoff Address', id: 'exact_dropoff_address', type: 'address'},
    {name: 'Drop-off Loc.', id: 'dropoff_location', type: 'address'},
    {name: 'Freight Rate', id: 'freight_rate'},
    {name: 'Company name (Buyer)', id: 'buyer'},
    {name: 'Supplier', id: 'supplier'},
    {name: 'Product', id: 'product_name'},
    {name: 'Item Type', id: 'item_type'},
    {name: 'Brand', id: 'brand'},
    {name: 'Origin', id: 'origin'},
    {name: 'ETD', id: 'etd', type: 'date'},
    {name: 'ETA', id: 'eta', type: 'date'},
    {name: 'Container number', id: 'container_num'},
    {name: 'Booking #', id: 'booking_num'},
    {name: 'Vessel', id: 'vessel'},
    {name: 'Rate', id: 'rate'},
    {name: 'Temperature', id: 'temperature'},
    {name: 'Macropoint Identifier', id: 'macropoint_order_identifier'},
    {name: 'Macropoint Order Status', id: 'macropoint_order_status_name'},
    {name: 'Macropoint Latest Location', id: 'macropoint_order_latest_location_city_name'},
    {name: 'Macropoint Order Delivery Status', id: 'macropoint_order_delivery_status_name'},
    {name: 'Shipping Line', id: 'shipping_line'},
    {name: 'Courier Track. #', id: 'courier_tracking_no',
        template: `<div class="ui-grid-cell-contents" ng-if="row.entity.courierTrackings" title="{{row.entity.courierTrackingNums}}">
    <span ng-repeat="item in row.entity.courierTrackings"><a ng-href="{{item.tracking_link}}" target="_blank">{{item.tracking_number}}</a> </span>
  </div>`,
    },
]

export const availableShippingLogHeaders = sortBy(availableShippingLog, 'name')
export const availableSupplierShippingLogHeaders = sortBy(availableSupplierShippingLog, 'name')
export const availableServiceProviderShippingLogHeaders = sortBy(availableServiceProviderShippingLog, 'name')

export const columnsMap = {
    [BUYER]: {
        deal_no: 'deal_id',
        status: 'status',
        date: 'deal_date',
        supplier: 'supplier',
        buyer: 'buyer',
        comment: 'comment',
        consignee: 'consignee',
        origin: 'origin_country',
        destination: 'dest_location',
        pickup: 'pickup_location',
        delivery: 'dest_country',
        delivery_period: 'shipment_date',
        pickup_date: 'pickup_date',
        pickup_time: 'pickupTime',
        delivery_date: 'dropoff_date',
        delivery_time: 'deliveryTime',
        import_permit: 'import_permit',
        import_no: 'import_no',
        container_num: 'container_number',
        courier_tracking_no: 'courier_track_id',
        truck: 'truck_refer_id',
        vessel: 'vessel',
        shipping_line: 'vessel_carrier',
        on_board_date: 'etd',
        inv_amount: 'actual_revenue',
        inv_extra_costs: 'credit_notes_negative_total',
        outstanding_balance: 'credit_balance',
        product: 'product',
        desc_of_goods: 'status_b',
        size_of_package: 'size_of_package',
        net_wight: 'total_weight',
        no_of_packages: 'packages_count',
        type_of_packages: 'type_of_packages',
        unit_sell_price: 'sell_price',
        selling_ref_no: 'buyer_ref',
        booking_num: 'booking_id',
        actual_payment_date: 'collection_date',
        additional_info: 'note_comments_public',
        shipper: 'shipper',
        docs_status: 'original_sent',
        value_of_docs_release: 'value_of_docs_release',
        inv_no: 'buyer_invoice_id',
        inv_date: 'buyer_invoice_date',
        due_date: 'buyer_invoice_due',
        prepayment_amount: 'credit_notes_positive_total',
        payment_received: 'prepayment_received',
        payment_terms: 'payment_terms_buyer',
        prepayment_percent: 'prepayment_percent',
        supplier_paid_date: 'supplier_invoice_paid',
        voyage_no: 'voyage_no',
        epd_date: 'epd',
        erd_date: 'erd',
        establishments: 'est_id',
        brand: 'brand',
        forwarder: 'forwarder',
        exact_dropoff_address: 'exact_dropoff_address',
        vendor_invoice_number_mx: 'foreign_invoice_number_mx',
        wrapping: 'wrappings',
        current_carrier_location: 'macropoint_order_latest_location_city_name',
        shipment_tracking_status: 'macropoint_order_status_name',
        carrier_eta: 'carrier_eta',
    },

    [SUPPLIER]: {
        deal_id: 'deal_id',
        status: 'shipment_status',
        cutOff: 'vessel_cutoff_date',
        vessel_cutoff_date: 'vessel_cutoff_date',
        estLoadingDate: 'estimated_loading_date',
        loadingDate: 'pickup_date',
        loadingAddress: 'exact_loading_address',
        vessel: 'vessel',

        booking: 'booking_id',
        trackingNum: 'courier_track_id',
        destination: 'dest_country',
        supplierRef: 'supplier_ref',
        productName: 'product',

        weight: 'actual_weight_buy',
        estWeight: 'estimated_weight',
    },

    [SERVICE_PROVIDER]: {
        deal_id: 'deal_id',
        booking_num: 'booking_id',
        brand: 'brand',
        buyer: 'buyer',
        container_num: 'container_number',
        courier_tracking_no: 'courier_track_id',
        dropoff_location: 'dropoff_location',
        dropoff_date: 'dropoff_date',
        eta: 'eta',
        etd: 'etd',
        exact_dropoff_address: 'exact_dropoff_address_full',
        freight_rate: 'freight_rates_amount',
        shipping_line: 'vessel_carrier',
        supplier: 'supplier',
        item_type: 'item_type',
        load_date: 'pickup_date',
        vessel: 'vessel',
        loading_loc: 'pickup_location',
        origin: 'origin_country',
        product_name: 'product',
        exact_loading_address: 'exact_loading_address_full',
        rate: 'rate',
        temperature: 'temperature',
        macropoint_order_identifier: 'macropoint_order_identifier',
        macropoint_order_status_name: 'macropoint_order_status_name',
        macropoint_order_latest_location_city_name: 'macropoint_order_latest_location_city_name',
        macropoint_order_delivery_status_name: 'macropoint_order_delivery_status_name',
    },
}
